<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <b-link class="brand-logo">
        <vuexy-logo />
<!--        <h2 class="brand-text text-primary ml-1">
          TOTO
        </h2>-->
      </b-link>

      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>

      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Welcome to TOTO Work Permit!
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text>

          <validation-observer ref="loginValidation"  #default="{invalid}">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <b-form-group>
                <label>Login type</label>
                <b-form-radio-group v-model="loginType" v-show="!selectCompany">
                  <b-form-group>
                    <b-form-radio name="Login type" value="2">Phone number</b-form-radio>
                    <b-form-radio name="Login type" value="1">Email</b-form-radio>
                    <br>
                    <b-form-radio name="Login type" value="3" class="mt-1">Staff ID <span class="text-danger">(Only TOTO staff)</span></b-form-radio>
                  </b-form-group>
                </b-form-radio-group>
                <div v-show="selectCompany"><strong>{{usernameLabel}}</strong></div>
              </b-form-group>


              <b-form-group>
                <label for="login-password">{{ usernameLabel }}</label>
                <validation-provider #default="{ errors }" :name="usernameLabel" :rules="usernameValidate" v-show="!selectCompany">
                  <b-form-input v-model="userEmail" :state="errors.length > 0 ? false:null" :name="usernameLabel" :placeholder="usernamePlaceholder"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <div v-show="selectCompany"><strong>{{userEmail}}</strong></div>
              </b-form-group>

              <b-form-group>
                <div class="d-flex justify-content-between" >
                  <label for="login-password">Password</label>
                  <b-link :to="{name:'auth-forgot-password-v2'}" v-if="false">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="รหัสผ่าน"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group v-if="selectCompany">
                <label for="login-password">Company</label>
                <validation-provider #default="{ errors }" name="company" rules="required">
                  <v-select v-model="companyId" label="companyName"
                            :options="optionCompanies" :reduce="option => option.companyId"
                            :class="errors.length > 0 ? 'vue-select is-invalid' : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                  :disabled="isBtnDisabled"
                  type="submit"
                  variant="primary"
                  block
                  @click="validationForm"
                  class="mt-3"
              >
                <b-spinner small v-if="isBtnDisabled"/>
                <feather-icon icon="LogInIcon" v-else/>
                Sign in
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="mt-2">
            <b-row>
              <b-col class="text-right">
                <a href="https://goo.gl/maps/dopSfjhnvA1L6cKj7" target="_blank">
                  <feather-icon icon="MapPinIcon" size="40"/>
                  <div style="margin-top: 0.5rem;">TTL 1-2</div>
                </a>
              </b-col>
              <b-col>
                <a href="https://goo.gl/maps/e9FRVWXigi5BvWgq5" target="_blank">
                  <feather-icon icon="MapPinIcon" size="40"/>
                  <div style="margin-top: 0.5rem;">TTL 3</div>
                </a>
              </b-col>
            </b-row>
          </b-card-text>

        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BFormRadio, BFormRadioGroup, BCardText, BCardTitle, BImg, BForm, BButton, BSpinner
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BFormRadio,
    BFormRadioGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  created() {
    if(this.userData && this.userData.userId) {
      this.$router.replace('/')
    }
  },
  data() {
    return {
      isBtnDisabled: false,
      status: '',
      loginType: '2',
      password: '',
      userEmail: '',
      // password: '!QAZ2wsx3edc',
      // userEmail: 'test24@gmail.com',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rules import store from '@/store/index'
      required,
      email,
      selectCompany: false,
      companyId: '',
      optionCompanies: [],
      prevRoute: null,
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    usernameValidate() {
      return this.loginType === '1' ? 'required|email' : 'required'
    },
    usernamePlaceholder() {
      if (this.loginType === '1') {
        return 'อีเมล'
      } else if (this.loginType === '2') {
        return 'เบอร์โทรศัพท์'
      } else if (this.loginType === '3') {
        return 'รหัสพนักงาน'
      }
    },
    usernameLabel() {
      if (this.loginType === '1') {
        return 'Email'
      } else if (this.loginType === '2') {
        return 'Phone number'
      } else if (this.loginType === '3') {
        return 'Staff ID'
      }
    }
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.isBtnDisabled = true

          useJwt.login({
            userLoginType: this.loginType,
            userLogin: this.userEmail,
            userPassword: this.password,
            userCompanyId: this.companyId,
            userSelectCompany: this.selectCompany
          }).then(response => {
            this.isBtnDisabled = false
            const {result} = response.data
            const {selectCompany, userData, accessToken, refreshToken, companyLists} = result

            if(selectCompany) {
              this.selectCompany = selectCompany
              this.optionCompanies = companyLists

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Please select your company',
                  icon: 'AlertTriangleIcon',
                  variant: 'warning',
                },
              })
            } else {
              useJwt.setToken(accessToken)
              useJwt.setRefreshToken(refreshToken)
              useJwt.setScope(userData.scope)
              localStorage.setItem('userData', JSON.stringify(userData))

              let loginSuccessUrl = this.prevRoute ? this.prevRoute.fullPath : '/'

              this.$router.replace(loginSuccessUrl)
                  .then(() => {
                    // this.$store.commit('main/setToastSuccess', {title: `Welcome ${userData.userFullName}`, text: `You have successfully logged. Now you can start to explore!`})
                  })
            }
          }).catch(() => {
            this.isBtnDisabled = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Login fail',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

</style>
