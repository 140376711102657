var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('vuexy-logo')],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Login V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" Welcome to TOTO Work Permit! ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Please sign-in to your account and start the adventure ")]),_c('validation-observer',{ref:"loginValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',[_c('label',[_vm._v("Login type")]),_c('b-form-radio-group',{directives:[{name:"show",rawName:"v-show",value:(!_vm.selectCompany),expression:"!selectCompany"}],model:{value:(_vm.loginType),callback:function ($$v) {_vm.loginType=$$v},expression:"loginType"}},[_c('b-form-group',[_c('b-form-radio',{attrs:{"name":"Login type","value":"2"}},[_vm._v("Phone number")]),_c('b-form-radio',{attrs:{"name":"Login type","value":"1"}},[_vm._v("Email")]),_c('br'),_c('b-form-radio',{staticClass:"mt-1",attrs:{"name":"Login type","value":"3"}},[_vm._v("Staff ID "),_c('span',{staticClass:"text-danger"},[_vm._v("(Only TOTO staff)")])])],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectCompany),expression:"selectCompany"}]},[_c('strong',[_vm._v(_vm._s(_vm.usernameLabel))])])],1),_c('b-form-group',[_c('label',{attrs:{"for":"login-password"}},[_vm._v(_vm._s(_vm.usernameLabel))]),_c('validation-provider',{directives:[{name:"show",rawName:"v-show",value:(!_vm.selectCompany),expression:"!selectCompany"}],attrs:{"name":_vm.usernameLabel,"rules":_vm.usernameValidate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"name":_vm.usernameLabel,"placeholder":_vm.usernamePlaceholder},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectCompany),expression:"selectCompany"}]},[_c('strong',[_vm._v(_vm._s(_vm.userEmail))])])],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-password"}},[_vm._v("Password")]),(false)?_c('b-link',{attrs:{"to":{name:'auth-forgot-password-v2'}}},[_c('small',[_vm._v("Forgot Password?")])]):_vm._e()],1),_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false:null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"รหัสผ่าน"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.selectCompany)?_c('b-form-group',[_c('label',{attrs:{"for":"login-password"}},[_vm._v("Company")]),_c('validation-provider',{attrs:{"name":"company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? 'vue-select is-invalid' : null,attrs:{"label":"companyName","options":_vm.optionCompanies,"reduce":function (option) { return option.companyId; }},model:{value:(_vm.companyId),callback:function ($$v) {_vm.companyId=$$v},expression:"companyId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('b-button',{staticClass:"mt-3",attrs:{"disabled":_vm.isBtnDisabled,"type":"submit","variant":"primary","block":""},on:{"click":_vm.validationForm}},[(_vm.isBtnDisabled)?_c('b-spinner',{attrs:{"small":""}}):_c('feather-icon',{attrs:{"icon":"LogInIcon"}}),_vm._v(" Sign in ")],1)],1)]}}])}),_c('b-card-text',{staticClass:"mt-2"},[_c('b-row',[_c('b-col',{staticClass:"text-right"},[_c('a',{attrs:{"href":"https://goo.gl/maps/dopSfjhnvA1L6cKj7","target":"_blank"}},[_c('feather-icon',{attrs:{"icon":"MapPinIcon","size":"40"}}),_c('div',{staticStyle:{"margin-top":"0.5rem"}},[_vm._v("TTL 1-2")])],1)]),_c('b-col',[_c('a',{attrs:{"href":"https://goo.gl/maps/e9FRVWXigi5BvWgq5","target":"_blank"}},[_c('feather-icon',{attrs:{"icon":"MapPinIcon","size":"40"}}),_c('div',{staticStyle:{"margin-top":"0.5rem"}},[_vm._v("TTL 3")])],1)])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }